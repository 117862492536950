<template>
  <v-app>
    <v-main class="main">
      <router-view />
      <!-- <dashboard-core-footer /> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Auth",
  components: {},

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
<style>
.main {
  background-image: url("../assets/login-background2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
